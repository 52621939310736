import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from "react-router-dom";
import NavBar from '../NavBar';
import Footer from '../../components/Footer'


const items = [{ link: "/de", label: "Deutsch" }, { link: "/fr", label: "Français" },{ link: "/logout", label: "Ausloggen / Se déconnecter" }]

const useStyles = makeStyles((theme) => ({

  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
      marginTop: "64px",
    },
  },
  box: {
    color: "#0B5AA1 !important",
    marginTop: "50px",
    color: "#0B5AA1",


    '&:hover': {
      color: "#073F71 !important"
    },

  },
  container: {
    padding: '0', maxWidth: "100%", minHeight: "100vh", position: "relative", paddingBottom: "100px", display: "block",
  
    [theme.breakpoints.down('sm')]: {
      paddingBottom: "200px",
    },
  
  }



}));



const TITLE = 'Kick-Off 2021'


export default function Home(props) {
  const classes = useStyles()
  let { params } = useParams();

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <NavBar items={items} burger={true}/>
      <Container className={classes.container}>


      <img className={classes.titleImage} src="https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/berner/assets/2104+Berner+-+Kick-Off+2021+-+Berner+-+Titelgrafik+v001.jpg" />

      <Container maxWidth="md">

      <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={3}
                      className={classes.box}
                    >
                      <Grid  item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3 style={{textAlign: "center"}} >Wähle deine Sprache / Choisissez votre langue</h3>
                      </Grid>
                      <Grid item xs={5} sm={4} md={4} lg={4} xl={4}>
                        <Link to="/de">
                          <img src="https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/berner/assets/2104+Berner+-+Kick-Off+2021+-+Flaggen+v001+-+Germany.svg" />
                        </Link>
                      </Grid>
                      <Grid item xs={5} sm={4} md={4} lg={4} xl={4}>
                        <Link to="/fr">
                          <img src="https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/berner/assets/2104+Berner+-+Kick-Off+2021+-+Flaggen+v001+-+France.svg" />
                        </Link>
                      </Grid>
                    </Grid>

      </Container>
      <Footer />

</Container>

    </>
  )
}

