import React, { Fragment, useEffect } from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import { Container } from '@material-ui/core';
import ApolloProvider from './ApolloProvider'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import './App.scss';

import { deDE } from '@material-ui/core/locale';
import { AuthProvider } from './context/auth'

import Stream from './pages/home/Stream'
import StreamFr from './pages/home/StreamFr'
import Landing from './pages/Landing'
import Admin from './pages/Admin'
import WelcomeDe from './pages/home/Welcome';
import WelcomeFr from './pages/home/WelcomeFr';
import Selection from './pages/home/Selection';
import Logout from './pages/home/Logout.js';
import AfterEvent from './pages/home/AfterEvent';
import AfterEventFr from './pages/home/AfterEventFr';
import Footer from './components/Footer'
import DynamicRoute from './util/DynamicRoute'
import TawkTo from './components/TawkTo';

const theme = createMuiTheme({

  palette: {
    primary: { main: '#0B5AA1' },
  }

}, deDE)



const useStyles = makeStyles((theme) => ({

container: {
  padding: '0', maxWidth: "100%",

}
  

}));

  



function App() {
  const classes = useStyles()


  useEffect(() => {
  }, [])

  return (
    <Fragment>
      <ApolloProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <BrowserRouter >
              <Container className={classes.container}>
                <Switch>
                <DynamicRoute exact path="/" component={Selection} authenticated />
                  <DynamicRoute exact path="/landing" component={Landing} guest />
                  <DynamicRoute exact path="/admin" component={Admin} guest />
                  <DynamicRoute exact path="/de/stream" component={Stream} authenticated />
                  <DynamicRoute exact path="/fr/stream" component={StreamFr} authenticated />
                  <DynamicRoute exact path="/logout" authenticated ><Logout /></DynamicRoute>
                  <DynamicRoute exact path="/de/fin" authenticated ><AfterEvent /></DynamicRoute>
                  <DynamicRoute exact path="/fr/fin" authenticated ><AfterEventFr /></DynamicRoute>
                  <DynamicRoute exact path="/de" component={WelcomeDe} authenticated />
                  <DynamicRoute exact path="/fr" component={WelcomeFr} authenticated />
                  <DynamicRoute path="/de/:params" component={WelcomeDe} authenticated />
                  <DynamicRoute path="/fr/:params" component={WelcomeFr} authenticated />
                  <DynamicRoute path="*" authenticated><Redirect to={"/"} /></DynamicRoute>
                </Switch>
                <TawkTo />
              </Container>
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Fragment>

  );
}

export default App;
