import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import LoginForm from '../components/LoginForm';
import NavBar from './NavBar';
import Footer from '../components/Footer'
import Container from '@material-ui/core/Container';

const TITLE = 'Kick-Off 2021'



const useStyles = makeStyles((theme) => ({ 
 
  titleImage:{
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
    marginTop: "64px",
    },  
   },
   container: {
    padding: '0', maxWidth: "100%", minHeight: "100vh", position: "relative", paddingBottom: "100px", display: "block",
  
    [theme.breakpoints.down('sm')]: {
      paddingBottom: "200px",
    },
  
  }

}));


const items = [{ }]




export default function Landing(props) {
  const myRef = useRef()
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));



  useEffect(() => {
      
  
  }, []);




  return (
    <>
    <Helmet>
          <title>{ TITLE }</title>
        </Helmet>       
      
        <NavBar items={items} />
        <Container className={classes.container}>

<img className={classes.titleImage} src="https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/berner/assets/2104+Berner+-+Kick-Off+2021+-+Berner+-+Titelgrafik+v001.jpg"/>

      <div ref={myRef}><LoginForm /></div>

    
      <Footer />

      </Container>
    </>
    
  )
}
