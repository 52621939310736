import { gql, useMutation } from '@apollo/client'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, {  useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '../NavBar'
import Player from './player3Q'
import Container from '@material-ui/core/Container';
import Footer from '../../components/FooterFr'

import Guardien from './GuardianFr'

const items = [{ link: "/de/stream", label: "Deutsch" }, { link: "/logout", label: "Se déconnecter" }]


const TITLE = 'Kick-Off 2021'

const ADD_QUESTION = gql`
  mutation addQuestion($from: String!, $content: String!) {
    addQuestion(from: $from ,content: $content) {
      content
    }
  }
`;


const useStyles = makeStyles((theme) => ({
  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
      marginTop: "64px",
    },
  },
  box: {
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  },

  video: {
    position: "relative",
    paddingBottom: "56.25%",
    paddingTop: "0px",
    height: "0",
    borderRadius: "10px",
    marginBottom: "-1px",
    marginTop: "50px",
  },
  container: {
    padding: '0', maxWidth: "100%", minHeight: "100vh", position: "relative", paddingBottom: "100px", display: "block",
  
    [theme.breakpoints.down('sm')]: {
      paddingBottom: "200px",
    },
  
  }

}));


export default function Home(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickQuestion = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [variables, setVariables] = useState({
    from: '',
    content: '',
  })
  const [addQuestion, { data }] = useMutation(ADD_QUESTION);


  const handleSubmitQuestion = () => {
    addQuestion({ variables: { from: variables.from, content: variables.content } });
    setOpen(false);
  };

  useEffect(() => {

  }, []);


  return (
    <>
    <Guardien project={"berner-ch-fr"}/>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <NavBar items={items} burger={true} />

      <Container className={classes.container}>



      <img className={classes.titleImage} src="https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/berner/assets/2104+Berner+-+Kick-Off+2021+-+Berner+-+Titelgrafik+v002+-+FR.jpg" />

      <Container maxWidth="md">
        <div className={classes.box}>
          <div className={classes.video} id='vdiv'>  <Player project={"ch-fr"} dataId={"65241acc-976f-11eb-b839-0cc47a188158"} /></div>
        </div>
      </Container>
      <Footer />

      </Container>


      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Frage einreichen</DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmitQuestion()

          }}
        >
          <DialogContent>
            <DialogContentText>
              Sie können hier ihre Frage an den Referenten einreichen.
          </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="customer"
              label="Ihr Name.."
              type="text"
              fullWidth
              variant="outlined"
              onChange={e => setVariables({ ...variables, from: e.target.value })}

            />
            <TextField
              margin="dense"
              id="question"
              label="Ihre Frage.."
              type="text"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              onChange={e => setVariables({ ...variables, content: e.target.value })}

            />

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Abbrechen
          </Button>
            <Button color="primary" type="submit">
              Einreichen
          </Button>
          </DialogActions>
        </form>
      </Dialog>


    </ >
  );
}

