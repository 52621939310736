import React, { Fragment, useEffect } from 'react'

import tawkTo from "tawkto-react";



const tawkToPropertyId = '606d3684f7ce18270937cb4b'
const tawkToKey = '1f2l9ptn9'

function TawkTo() {

  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey)
  }, [])

  return null
  
}

export default TawkTo;
