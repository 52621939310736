import { gql, useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import { useAuthState} from '../../context/auth'


const GET_STREAM_ACCESS = gql`
  query getStreamAccess($project: String!){
    getStreamAccess(project: $project)
  }
`;


export default function Guardien({project}) {
  const { loading, error, data } = useQuery(GET_STREAM_ACCESS, {
    variables: { project: project },
    pollInterval: 30000,
  });

  const {user} = useAuthState()


  useEffect(() => {

  }, []);

  if(data ){
    if(user.ticket === "admin") return null
    if(data.getStreamAccess === "early") return (<Redirect to="/fr/closed"/>)
    if(data.getStreamAccess === "late") return (<Redirect to="/fr/fin"/>)

  }

  return null
}