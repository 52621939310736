import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
 footer: {
  position: "absolute", bottom: 0, width:"100%",
  display: "flex",
  justifyContent: "center"
 },
 flex: {
  margin: 0,
  display: "flex",
   flexFlow: "column wrap",
   justifyContent: "center",
   padding: "10px",
   
   [theme.breakpoints.up('sm')]: {
    flexDirection: "row"
  },
   
 },
 flexItem:{
  flexBasis: "auto",
  textAlign: "center",
  padding: "10px",
  borderLeft: "solid 0px white",
  [theme.breakpoints.up('sm')]: {
    borderLeft: "solid 0px white",
  },  
 },
 a: {
  color: "#666666",
  fontSize: "16px",
  fontWeight: 400
 },

}));


export default function Footer({id}) {
  const classes = useStyles()
  return (

<section className={classes.footer}>
<div className={classes.flex}>
        <div className={classes.flexItem}>
          <a className={classes.a} href="https://shop.berner.eu/ch-fr/imprint" target="_blank" rel="noreferrer">Mentions légales</a>
        </div>
        <div className={classes.flexItem}>
          <a className={classes.a} href="https://shop.berner.eu/ch-fr/Datenschutz" target="_blank" rel="noreferrer">Déclaration de protection des données</a>
        </div>
        <div className={classes.flexItem}>
          <a className={classes.a} href="https://shop.berner.eu/ch-fr/terms" target="_blank" rel="noreferrer">Conditions générales de vente</a>
        </div>
        <div className={classes.flexItem}>
          <a className={classes.a} href="https://wirkung.group/digital/" target="_blank" rel="noreferrer">La technologie</a>
        </div>
      </div>
</section>
     


  )
}
